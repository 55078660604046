var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 mx-4",attrs:{"fluid":""}},[_c('v-card',{staticClass:"mx-auto rounded-lg",attrs:{"outlined":"","id":"user-logs","max-width":"1400"}},[_c('v-card-title',[_c('span',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$ml.get("folders_sharing"))+" ")]),_c('download-csv',{ref:"downloadCsv",attrs:{"data":_vm.reports,"prepareData":_vm.prepareDataToDownload,"fileName":("sharing-reports-" + _vm.today),"prevent":true}},[_c('v-btn',{staticClass:"text-none text-body-2 font-weight-bold mr-2 my-2",attrs:{"text":"","color":"accent","loading":_vm.loadingLogs},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.downloadCsv.generate()}}},[_c('svg',{staticClass:"mr-2",staticStyle:{"width":"24px","height":"24px"},attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"}})]),_vm._v(" "+_vm._s(_vm.$ml.get("download_csv"))+" ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reports,"fixed-header":"","loading":_vm.loadingLogs,"mobile-breakpoint":"900","items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsOptions },"height":_vm.tableHeight,"sort-by":"files.length","sort-desc":true,"show-expand":"","single-expand":false,"item-key":"key"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"28"}},[_c('img',{attrs:{"src":item.photo || _vm.userDefaultImage,"alt":item.name}})]),_c('span',{staticClass:"blue--text text--darken-2 link",on:{"click":function($event){return _vm.$router.push({ name: 'User', params: { id: item.key } })}}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.total_size",fn:function(ref){
var item = ref.item;
return [(item.total_size)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.formatSize(item.total_size))+" ")]):_c('span',[_vm._v("-")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pl-4 pt-4 pb-4 grey lighten-4",attrs:{"colspan":_vm.headers.length}},[_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.fileHeaders,"items":item.files,"hide-default-footer":!item.files || (item.files && item.files.length <= 10),"items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsOptions },"height":"calc(60vh - 160px)"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"blue--text text--darken-2 link text-decoration-none",attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.has_access_external",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pr-3 py-1 font-weight-medium",attrs:{"outlined":"","small":"","color":item.has_access_external ? 'error' : 'success'}},[_vm._v(" "+_vm._s(_vm.getSharingText(item))+" ")])]}},{key:"item.file_extension",fn:function(ref){
var item = ref.item;
return [(item.file_extension)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.file_extension)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [(item.size)?_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.formatSize(item.size))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.permissions",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getUsersWithAccess(item)),function(email,index){return _c('v-chip',{key:index,staticClass:"mx-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(email)+" ")])})}}],null,true)})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }