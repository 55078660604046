<template>
  <div fluid class="pa-0 mx-4">
    <v-card outlined id="user-logs" max-width="1400" class="mx-auto rounded-lg">
      <v-card-title>
        <span class="mr-5">
          {{ $ml.get("folders_sharing") }}
        </span>

        <download-csv
          ref="downloadCsv"
          :data="reports"
          :prepareData="prepareDataToDownload"
          :fileName="`sharing-reports-${today}`"
          :prevent="true"
        >
          <v-btn
            text
            class="text-none text-body-2 font-weight-bold mr-2 my-2"
            color="accent"
            :loading="loadingLogs"
            @click.stop="$refs.downloadCsv.generate()"
          >
            <svg
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
              class="mr-2"
            >
              <path
                fill="currentColor"
                d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
              />
            </svg>
            {{ $ml.get("download_csv") }}
          </v-btn>
        </download-csv>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="reports"
        fixed-header
        :loading="loadingLogs"
        mobile-breakpoint="900"
        :items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsOptions }"
        :height="tableHeight"
        sort-by="files.length"
        :sort-desc="true"
        show-expand
        :single-expand="false"
        item-key="key"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="28" class="mr-2">
              <img :src="item.photo || userDefaultImage" :alt="item.name" />
            </v-avatar>
            <span
              class="blue--text text--darken-2 link"
              @click="$router.push({ name: 'User', params: { id: item.key } })"
              >{{ item.name }}</span
            >
          </div>
        </template>
        <template v-slot:[`item.total_size`]="{ item }">
          <v-chip small v-if="item.total_size">
            {{ formatSize(item.total_size) }}
          </v-chip>
          <span v-else>-</span>
        </template>
        <template v-slot:[`expanded-item`]="{ item }">
          <td :colspan="headers.length" class="pl-4 pt-4 pb-4 grey lighten-4">
            <v-data-table
              fixed-header
              :headers="fileHeaders"
              :items="item.files"
              :hide-default-footer="
                !item.files || (item.files && item.files.length <= 10)
              "
              :items-per-page="itemsPerPage"
              :footer-props="{ itemsPerPageOptions: itemsOptions }"
              height="calc(60vh - 160px)"
            >
              <template v-slot:[`item.name`]="{ item }">
                <a
                  class="blue--text text--darken-2 link text-decoration-none"
                  :href="item.link"
                  target="_blank"
                  >{{ item.name }}</a
                >
              </template>
              <template v-slot:[`item.has_access_external`]="{ item }">
                <v-chip
                  outlined
                  small
                  :color="item.has_access_external ? 'error' : 'success'"
                  class="pr-3 py-1 font-weight-medium"
                >
                  {{ getSharingText(item) }}
                </v-chip>
              </template>
              <template v-slot:[`item.file_extension`]="{ item }">
                <v-chip small v-if="item.file_extension">
                  {{ item.file_extension }}
                </v-chip>
                <span v-else>-</span>
              </template>
              <template v-slot:[`item.size`]="{ item }">
                <v-chip small v-if="item.size">
                  {{ formatSize(item.size) }}
                </v-chip>
                <span v-else>-</span>
              </template>
              <template v-slot:[`item.permissions`]="{ item }">
                <v-chip
                  class="mx-1 my-1"
                  small
                  v-for="(email, index) in getUsersWithAccess(item)"
                  :key="index"
                >
                  {{ email }}
                </v-chip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { paging, tableHeight, today } from "@/helpers/utils";
import { SCOPE_ERRO_CODE } from "@/helpers/variables";
import JsonCSV from "@/components/main/shared/Download.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ReportsFoldersSharing",
  components: { "download-csv": JsonCSV },
  data() {
    return {
      today,
      loadingLogs: false,
      showDataTable: true,
      headers: [
        { text: this.$ml.get("user"), value: "name" },
        { text: this.$ml.get("shared_files"), value: "files.length" },
        {
          text: this.$ml.get("files_shared_externally"),
          value: "external_access_count",
        },
        {
          text: this.$ml.get("full_size"),
          value: "total_size",
        },
        {
          text: "",
          value: "data-table-expand",
          align: "end",
          sortable: false,
          show: true,
        },
      ],
      fileHeaders: [
        { text: this.$ml.get("name"), value: "name" },
        {
          text: this.$ml.get("sharing"),
          value: "has_access_external",
          width: 180,
        },
        { text: this.$ml.get("users_with_access"), value: "permissions" },
        { text: this.$ml.get("size"), value: "size" },
        {
          text: this.$ml.get("extension"),
          value: "file_extension",
          width: 100,
        },
      ],
      reports: [],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight,
      allFiles: [],
    };
  },
  computed: {
    ...mapGetters(["userDefaultImage"]),
  },
  methods: {
    ...mapActions(["setSnackbarDefault"]),
    ...mapMutations(["setMissingScopesControl"]),

    getSharingText(file) {
      return file.has_access_external
        ? this.$ml.get("external")
        : this.$ml.get("internal");
    },

    getUsersWithAccess(file) {
      const permissions = JSON.parse(file.permissions);
      return permissions.map(
        (permission) =>
          permission.email_address ||
          permission.name ||
          this.$ml.get("anyone_with_link")
      );
    },

    prepareDataToDownload() {
      return this.allFiles.map((file) => ({
        [this.$ml.get("name")]: file.name,
        [this.$ml.get("sharing")]: this.getSharingText(file),
        [this.$ml.get("users_with_access")]: this.getUsersWithAccess(file),
        link: file.link,
        [this.$ml.get("size")]: file.size ? this.formatSize(file.size) : "-",
        [this.$ml.get("extension")]: file.file_extension || "-",
      }));
    },
    formatSize(sizeBytes) {
      if (sizeBytes >= 1000) {
        const sizeKB = sizeBytes / 1000;

        if (sizeKB >= 1000) {
          const sizeMB = sizeKB / 1000;

          if (sizeMB >= 1000) {
            const sizeGB = sizeMB / 1000;
            return sizeGB.toFixed(2) + "GB";
          }

          return sizeMB.toFixed(2) + "MB";
        }

        return sizeKB.toFixed(2) + "KB";
      } else {
        return sizeBytes + " bytes";
      }
    },

    formatReports(reports) {
      const formatedReports = reports.map((report) => {
        let external_access_count = 0;
        let total_size = 0;

        report.files.map((file) => {
          this.allFiles.push(file);

          if (file.has_access_external) {
            external_access_count += 1;
          }

          if (file.size) {
            total_size += parseFloat(file.size);
          }
        });
        return { ...report, external_access_count, total_size };
      });

      this.reports = formatedReports;
    },

    async getLogs() {
      this.loadingLogs = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/folders-reports`;

      this.$axios
        .get(url)
        .then(({ data }) => {
          this.formatReports(data);
        })
        .catch(({ response: { data } }) => {
          let error_message_key = "error_reports";
          if (data.code === SCOPE_ERRO_CODE) {
            error_message_key = "scope_error";
            this.setMissingScopesControl(false);
          }

          this.setSnackbarDefault({
            message: error_message_key,
            color: "error",
            show: true,
            timeout: 100000,
          });
        })
        .finally(() => (this.loadingLogs = false));
    },
  },
  beforeMount() {
    this.getLogs();
  },
};
</script>
